import React from "react";

import ContactComponent from "../component/ContactComponent";
import Footer from "../component/Footer";
import Empty from "../component/Empty";
const Contact = () => {
  return (
    <div>
      <ContactComponent />
      <Empty />
      <Footer />
    </div>
  );
};

export default Contact;
