import React from "react";
import "bootstrap/js/src/collapse.js";
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import logo from "../assets/JPEG/logo.png";

function Header({ history }) {
  return (
    <>
      <nav
        id="main-nav"
        class="navbar navbar-dark shadow-5-strong navbar-expand-md  justify-content-center"
      >
        <div class="container">
          <a href="/" class="navbar-brand d-flex w-50 me-auto">
            <img id="logo" alt="logo" src={logo} />
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapsingNavbar3"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="navbar-collapse collapse w-100" id="collapsingNavbar3">
            <ul class="navbar-nav w-100 justify-content-center">
              <li class="nav-item">
                <Link to="/" class="nav-link" style={{ color: "white" }}>
                  Home
                </Link>
              </li>
              <li class="nav-item">
                <Link
                  to="/websites"
                  class="nav-link"
                  style={{ color: "white" }}
                >
                  Websites
                </Link>
              </li>
              <li class="nav-item">
                <Link
                  to="/applications"
                  class="nav-link"
                  style={{ color: "white" }}
                >
                  Applications
                </Link>
              </li>
              <li class="nav-item">
                <Link to="/ui" class="nav-link" style={{ color: "white" }}>
                  UI Design
                </Link>
              </li>
            </ul>
            <ul class="nav navbar-nav ms-auto w-100 justify-content-end">
              <li class="nav-item">
                <Link
                  type="button"
                  class="btn-contact btn me-2 mt-1 btn-dark"
                  style={{ borderRadius: "2rem" }}
                  to="/contact"
                >
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Header;
