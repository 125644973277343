import React from "react";
import InvoiceContactComponenet from "../component/InvoiceContactComonent";
import AiComponent from "../component/AiComponent";
import Footer from "../component/Footer";
const Ai = () => {
  return (
    <div>
      <AiComponent />
      <InvoiceContactComponenet />
      <Footer />
    </div>
  );
};

export default Ai;
