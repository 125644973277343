import React from "react";
import InvoiceContactComponenet from "../component/InvoiceContactComonent";
import ApplicationComponent from "../component/ApplicationComponent";
import Footer from "../component/Footer";
const Applications = () => {
  return (
    <div>
      <ApplicationComponent />
      <InvoiceContactComponenet />
      <Footer />
    </div>
  );
};

export default Applications;
