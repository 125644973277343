import React from "react";
import Adam from "../assets/JPEG/BlankTemplate.jpg";
import Bikers from "../assets/JPEG/bikers2.jpg";
import PortfolioPic from "../assets/JPEG/Portfolio.PNG";
import SongFork from "../assets/JPEG/songfork3.jpg";
import Omar from "../assets/JPEG/omarLogo5.jpg";
import Sav from "../assets/JPEG/Savannah2.jpg";
import Mom from "../assets/JPEG/mom.JPG";
import { Globe } from "react-bootstrap-icons";
const Portfolio = () => {
  return (
    <div>
      <section
        id="portfolio"
        class="portfolio"
        style={{ marginBottom: "5rem" }}
      >
        <div class="container" data-aos="fade-up">
          <header class="section-header">
            <h2 style={{ color: "white" }}>Portfolio</h2>
            <p style={{ color: "white" }}>Check Our Latest Work</p>
          </header>

          <div
            class="row gy-4 portfolio-container"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <div class="col-lg-4 col-md-6 portfolio-item filter-web">
              <div class="portfolio-wrap">
                <img src={Mom} class="img-fluid" alt="" />
                <div class="portfolio-info">
                  <h4>Breastfeeding Telehealth of Oklahoma </h4>
                  <p>Website</p>
                  <div class="portfolio-links">
                    <a
                      href="https://breastfeedingoklahoma.com/"
                      target="_blank"
                      title="More Details"
                    >
                      <Globe />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 portfolio-item filter-web">
              <div class="portfolio-wrap">
                <img src={Omar} class="img-fluid" alt="" />
                <div class="portfolio-info">
                  <h4>Tabogoo</h4>
                  <p>Website</p>
                  <div class="portfolio-links">
                    <a
                      href="https://tabogoo.com/"
                      target="_blank"
                      title="More Details"
                    >
                      <Globe />
                    </a>
                  </div>
                  <p>App</p>
                  <p>In Development</p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 portfolio-item filter-web">
              <div class="portfolio-wrap">
                <img src={Sav} class="img-fluid" alt="" />
                <div class="portfolio-info">
                  <h4>Savannah Martinez </h4>
                  <p>Website</p>
                  <div class="portfolio-links">
                    <a
                      href="https://savannahmartinez.com"
                      target="_blank"
                      title="More Details"
                    >
                      <Globe />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 portfolio-item filter-web">
              <div class="portfolio-wrap">
                <img src={Adam} class="img-fluid" alt="" />
                <div class="portfolio-info">
                  <h4>BlankTemplate</h4>
                  <p>Website</p>
                  <div class="portfolio-links">
                    <a
                      href="https://www.blankadam.com/"
                      target="blank_"
                      title="More Details"
                    >
                      <Globe />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 portfolio-item filter-web">
              <div class="portfolio-wrap">
                <img src={Bikers} class="img-fluid" alt="" />
                <div class="portfolio-info">
                  <h4>Bikers For Le Bonheur </h4>
                  <p>Website</p>
                  <div class="portfolio-links">
                    <a
                      href="https://bikersforlebonheur.com/"
                      title="More Details"
                      target="_blank"
                    >
                      <Globe />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 portfolio-item filter-app">
              <div class="portfolio-wrap">
                <img src={SongFork} class="img-fluid" alt="" />
                <div class="portfolio-info">
                  <h4>SongFork</h4>
                  <p>App</p>
                  <div class="portfolio-links">
                    <p>In Development</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Portfolio;
