import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon } from "mdbreact";
import Swal from "sweetalert2";
import { Envelope, Telephone } from "react-bootstrap-icons";
const Contact = () => {
  const form = useRef();

  const handleSubmit = (event) => {
    document.getElementById("my_form").reset();
  };

  const sendEmail = (e) => {
    e.preventDefault();
    // handleSubmit();

    emailjs
      .sendForm(
        "service_axyd35g",
        "template_fzodgxt",
        form.current,
        "p-jkjcMntSyoOOkMM"
      )
      .then(
        (result) => {
          Swal.fire({
            icon: "success",
            title: `<h5 style='color:green'>Message Sent Successfully</h5>`,
            confirmButtonColor: "green",
          });
        },
        (error) => {
          Swal.fire({
            icon: "error",
            title: `<h5 style='color:red'>Ooops, something went wrong</h5>`,
            confirmButtonColor: "red",
          });
        }
      );
    e.target.reset();
  };
  return (
    <div>
      <section id="contact" class="contact">
        <div class="container" data-aos="fade-up">
          <header class="section-header">
            <p style={{ color: "white" }}>Contact Us</p>
          </header>

          <div class="row gy-4">
            <div class="col-lg-6">
              <div class="row gy-4">
                <div class="row-md-6">
                  <div class="info-box" style={{ borderRadius: "2rem" }}>
                    <Telephone style={{ color: "black" }} size={50} />
                    <h3>Call Us</h3>
                    <p>405-633-6042</p>
                  </div>
                </div>
                <div class="row-md-6">
                  <div class="info-box" style={{ borderRadius: "2rem" }}>
                    <Envelope style={{ color: "black" }} size={50} />
                    <h3>Email Us</h3>
                    <p>contact@devicefield.com</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6">
              <form
                id="my_form"
                ref={form}
                onSubmit={sendEmail}
                class="php-email-form"
                style={{ borderRadius: "2rem" }}
              >
                <div class="row gy-4">
                  <div for="name" class="col-md-6">
                    <input
                      style={{ borderRadius: "2rem" }}
                      type="name"
                      id="name"
                      name="name"
                      class="form-control"
                      placeholder="Your Name"
                      required
                    />
                  </div>
                  <div for="email" class="col-md-6">
                    <input
                      style={{ borderRadius: "2rem" }}
                      type="email"
                      id="email"
                      name="email"
                      class="form-control"
                      placeholder="Your Email"
                      required
                    />
                  </div>

                  <div for="subject" class="col-md-12">
                    <input
                      style={{ borderRadius: "2rem" }}
                      type="text"
                      class="form-control"
                      id="subject"
                      name="subject"
                      placeholder="Subject"
                      required
                      //   style={{ borderColor: "#fafbff" }}
                    />
                  </div>

                  <div for="message" class="col-md-12">
                    <textarea
                      style={{ borderRadius: "2rem" }}
                      class="form-control"
                      id="message"
                      name="message"
                      rows="6"
                      placeholder="Message"
                      required
                    ></textarea>
                  </div>

                  <div class="col-md-12 text-center">
                    <button
                      onclick="sendmail()"
                      style={{ backgroundColor: "black", borderRadius: "2rem" }}
                      type="submit"
                      onChange={handleSubmit}
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;

// return (
<></>;
// <div>
//   <section className=" text-gray-700  body-font relative">
//     <div className="container px-5 py-5 mx-auto ">
//       <div className="flex flex-col text-center w-full mb-12"></div>
//       <form id="my_form" ref={form} onSubmit={sendEmail}>
//         <div className="lg:w-1/2 md:w-2/3 mx-auto">
//           <div className="flex flex-wrap -m-2">
//             <div className="p-2 w-1/2">
//               <div className="relative">
//                 <label
//                   for="name"
//                   className="leading-7 text-sm text-gray-600"
//                 >
//                   Name
//                 </label>
//                 <input
//                   type="text"
//                   id="name"
//                   name="user_name"
//                   className="w-full  rounded border border-gray-300 focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
//                 />
//               </div>
//             </div>
//             <div className="p-2 w-1/2">
//               <div className="relative">
//                 <label
//                   for="email"
//                   className="leading-7 text-sm text-gray-600"
//                 >
//                   Email
//                 </label>
//                 <input
//                   type="email"
//                   id="email"
//                   name="user_email"
//                   className="w-full  rounded border border-gray-300 focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
//                 />
//               </div>
//             </div>
//             <div className="p-2 w-full">
//               <div className="relative">
//                 <label
//                   for="message"
//                   className="leading-7 text-sm text-gray-600"
//                 >
//                   Message
//                 </label>
//                 <textarea
//                   id="message"
//                   name="message"
//                   className="w-full rounded border border-gray-300 focus:border-indigo-500 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
//                 ></textarea>
//               </div>
//             </div>
//             <div className="p-2 w-full">
//               <button
//                 type="submit"
//                 onChange={handleSubmit}
//                 class="w-full px-4 py-2 mt-2 font-medium text-center text-white bg-black transition-colors duration-200 rounded-md bg-black hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-1"
//               >
//                 Send
//               </button>
//             </div>

//             <div className="p-2 w-full pt-8 mt-8 border-t border-gray-200 text-center">
//               <a className="text-black">Savannahcmartinez09@gmail.com</a>
//               <p className="leading-normal my-5">
//                 Putnam City North
//                 <br />
//                 #3
//               </p>
//             </div>
//           </div>
//         </div>
//       </form>
//     </div>
//   </section>
// </div>
//   );
// };

// export default ContactComponent;
