import React from "react";
import { Facebook } from "react-bootstrap-icons";
import { Twitter } from "react-bootstrap-icons";
import { Instagram, EnvelopeFill } from "react-bootstrap-icons";

const Footer = () => {
  return (
    <div>
      <footer class="bg-dark text-center text-white ">
        <div class="container p-4 text-right" style={{ opacity: "0.5" }}>
          <section class="mb-4">
            <a
              class="btn btn-outline-light btn-floating m-1"
              href="https://www.facebook.com/profile.php?id=100095303211875&mibextid=LQQJ4d"
              target="_blank"
              role="button"
            >
              <Facebook size={30} />
            </a>

            <a
              class="btn btn-outline-light btn-floating m-1"
              href="https://www.twitter.com/devicefieldapps"
              target="_blank"
              role="button"
            >
              <Twitter size={30} />
            </a>

            <a
              class="btn btn-outline-light btn-floating m-1"
              href="https://www.instagram.com/devicefieldapps"
              target="_blank"
              role="button"
            >
              <Instagram size={30} />
            </a>

            <a
              class="btn btn-outline-light btn-floating m-1"
              onClick={() =>
                (window.location = "mailto:contact@devicefield.com")
              }
              role="button"
            >
              <EnvelopeFill size={30} />
            </a>
          </section>
          {/* <section class="">
            <form action="">
              <div class="row d-flex justify-content-center">
                <div class="col-auto">
                  <p class="pt-2">
                    <strong>Sign up for our newsletter</strong>
                  </p>
                </div>

                <div class="col-md-5 col-12">
                  <div class="form-outline form-white mb-4">
                    <input
                      style={{ borderRadius: "2rem" }}
                      type="email"
                      id="form5Example2"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-auto">
                  <button
                    style={{ borderRadius: "2rem" }}
                    type="submit"
                    class="btn btn-outline-light mb-4 p-2"
                  >
                    Subscribe
                  </button>
                </div>
              </div>
            </form>
          </section> */}
        </div>
        <div
          class="text-center p-3"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
        >
          © 2023 Copyright:
          <a class="text-white" href="https://devicefield.com/">
            Devicefield.com
          </a>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
