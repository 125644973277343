import React from "react";
import InvoiceContactComponenet from "../component/InvoiceContactComonent";
import UiComponent from "../component/UiComponent";
import Footer from "../component/Footer";
const Ui = () => {
  return (
    <div>
      <UiComponent />
      <InvoiceContactComponenet />
      <Footer />
    </div>
  );
};

export default Ui;
