import React from "react";
const Pricing = () => {
  return (
    <div>
      <section id="pricing" class="pricing">
        <div class="container" data-aos="fade-up">
          <header class="section-header">
            <h2 style={{ color: "white" }}>Pricing</h2>
            <p style={{ color: "white" }}>Check Our Pricing</p>
          </header>

          <div class="row gy-4" data-aos="fade-left">
            <div
              class="col-lg-3 col-md-6"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <div class="box">
                <h3 style={{ color: "#f68c09" }}>App Development</h3>
                <div>Starting at</div>
                <div class="price">
                  <sup>$</sup>2,499<span></span>
                </div>
                <img class="img-fluid" alt="" />
                <ul>
                  <li>Cross-platform compatibility</li>
                  <li>Database integration</li>
                  <li>User interface design</li>
                  <li>User authentication and security</li>
                  <li>Analytics and reporting</li>
                </ul>
                <a href="/applications" class="btn-buy">
                  Learn More
                </a>
              </div>
            </div>

            <div
              class="col-lg-3 col-md-6"
              data-aos="zoom-in"
              data-aos-delay="400"
            >
              <div class="box">
                <h3 style={{ color: "#f51f9c" }}>AI Development</h3>
                <div>Starting at</div>
                <div class="price">
                  <sup>$</sup>999<span></span>
                </div>
                <img class="img-fluid" alt="" />
                <ul>
                  <li>Custom AI Solutions</li>
                  <li>Integration with Existing Systems</li>
                  <li>Deployment and Maintenance</li>
                  <li>Chatbots and Virtual Assistants</li>
                  <li>Analytics and reporting</li>
                </ul>
                <a href="/ai" class="btn-buy">
                  Learn More
                </a>
              </div>
            </div>

            <div
              class="col-lg-3 col-md-6"
              data-aos="zoom-in"
              data-aos-delay="100"
            >
              <div class="box">
                <h3 style={{ color: "#2db6fa" }}>Website Development</h3>
                <div>Starting at</div>
                <div class="price">
                  <sup>$</sup>849<span></span>
                </div>
                <img class="img-fluid" alt="" />
                <ul>
                  <li>Content Management System</li>
                  <li>Responsive design</li>
                  <li>Search engine optimization</li>
                  <li>User authentication and security</li>
                  <li>Analytics and reporting</li>
                </ul>
                <a href="/websites" class="btn-buy">
                  Learn More
                </a>
              </div>
            </div>

            <div
              class="col-lg-3 col-md-6"
              data-aos="zoom-in"
              data-aos-delay="300"
            >
              <div class="box">
                <h3 style={{ color: "#b50edf" }}>UI Design</h3>
                <div>Starting at</div>
                <div class="price">
                  <sup>$</sup>449<span></span>
                </div>
                <img class="img-fluid" alt="" />
                <ul>
                  <li>Responsive design</li>
                  <li>Interactive elements</li>
                  <li>Consistent design</li>
                  <li>User testing</li>
                  <li>Personalization for custom projects </li>
                </ul>
                <a href="/ui" class="btn-buy">
                  Learn More
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Pricing;
