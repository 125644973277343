import React from "react";
const WebsiteComponent = () => {
  return (
    <div>
      <section id="blog" class="blog">
        <div class="container" data-aos="fade-up">
          <div class="row">
            <div class="col-lg-12 entries">
              <article
                class="entry entry-single"
                style={{
                  backgroundColor: "white",
                  borderRadius: "1rem",
                  paddingLeft: " 5%",
                  paddingRight: " 5%",
                }}
              >
                <h2 class="entry-title">
                  <a href="/websites" style={{ color: "#f68c09" }}>
                    Website Development
                  </a>
                </h2>
                <div
                  style={{ marginTop: 20, marginLeft: "5%" }}
                  class="entry-content"
                >
                  <h3>
                    🚀 Supercharge Your Online Presence with Expert Web
                    Development!
                  </h3>
                  <p>
                    In the digital age, your website is the cornerstone of your
                    success. Ready to elevate your brand and captivate your
                    audience? Look no further! Our cutting-edge website
                    development service is your gateway to a stellar online
                    presence.
                  </p>
                </div>
                <div
                  style={{
                    marginTop: 20,
                    marginLeft: "5%",
                  }}
                  class="entry-content"
                >
                  <p style={{ fontSize: 20, fontWeight: "bold" }}>
                    🎯 Tailored Solutions, Amplified Results
                  </p>
                  <p>
                    We don't just build websites; we craft digital experiences.
                    Our team of seasoned developers and creative minds
                    meticulously tailor each site to align with your brand's
                    vision and goals. Expect a seamless blend of captivating
                    design, impeccable functionality, and user-centric
                    interfaces, ensuring every click converts to meaningful
                    engagement.
                  </p>
                </div>
                <div
                  style={{ marginTop: 20, marginLeft: "5%" }}
                  class="entry-content"
                >
                  <p style={{ fontSize: 20, fontWeight: "bold" }}>
                    🌟 Unleash Innovation and Efficiency
                  </p>
                  <p>
                    Stay ahead of the curve with the latest technologies and
                    trends. We infuse innovation into every line of code,
                    ensuring your website isn't just a digital placeholder but a
                    dynamic hub that evolves with your business. From e-commerce
                    functionality to interactive elements, we transform ideas
                    into tangible, high-performance digital realities.
                  </p>
                </div>
                <div
                  style={{ marginTop: 20, marginLeft: "5%" }}
                  class="entry-content"
                >
                  <p style={{ fontSize: 20, fontWeight: "bold" }}>
                    🛡️ Robust Security and Reliability
                  </p>
                  <p>
                    Your peace of mind matters. We prioritize security measures,
                    integrating robust frameworks to fortify your website
                    against potential threats. Trust us to not only create a
                    stunning digital showcase but also ensure it remains
                    reliable and secure, fostering trust and confidence in your
                    audience.
                  </p>
                </div>
                <div
                  style={{ marginTop: 20, marginLeft: "5%" }}
                  class="entry-content"
                >
                  <p style={{ fontSize: 20, fontWeight: "bold" }}>
                    🤝 Your Success is Our Priority
                  </p>
                  <p>
                    We're not just here to build a website; we're here to build
                    a partnership. With a customer-centric approach, we value
                    your input and insight, working collaboratively to bring
                    your vision to life. Our support doesn't end at launch;
                    we're with you every step of the way, providing ongoing
                    support and maintenance.
                  </p>
                </div>
                <div
                  style={{ marginTop: 20, marginLeft: "5%" }}
                  class="entry-content"
                >
                  <p style={{ fontSize: 20, fontWeight: "bold" }}>
                    🌐 Seize the Digital Advantage
                  </p>
                  <p>
                    The online sphere is your arena, and a remarkable website is
                    your strongest asset. Embrace the power of a professionally
                    crafted digital presence and watch your business soar!
                  </p>
                </div>
                <div
                  style={{ marginTop: 20, marginLeft: "5%" }}
                  class="entry-content"
                >
                  <h3>Limited Clients Accepted: Act Now!</h3>
                  <p>
                    Ready to witness the power of a bespoke app for your
                    business? Contact us today to schedule a consultation and
                    receive a complimentary project assessment. Your success
                    story starts here!
                    <br />
                    <br />
                    Don't let your competition steal the spotlight. Embrace
                    innovation and stay ahead with Devicefield today!
                  </p>
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default WebsiteComponent;
