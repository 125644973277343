import React from "react";
import { ArrowRight, Folder2 } from "react-bootstrap-icons";
import { GlobeAmericas } from "react-bootstrap-icons";
import { App } from "react-bootstrap-icons";
import { Robot } from "react-bootstrap-icons";
import { Bag } from "react-bootstrap-icons";

import { PencilSquare } from "react-bootstrap-icons";
const Services = () => {
  return (
    <div>
      <section id="services" class="services">
        <div class="container" data-aos="fade-up">
          <header class="section-header">
            <h2 style={{ color: "white" }}>Services</h2>
            <p style={{ color: "white" }}>
              Your partner for all things digital
            </p>
          </header>

          <div class="row gy-4">
            <div
              class="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div class="service-box blue">
                <GlobeAmericas
                  class="icon"
                  style={{ marginBottom: "1rem", color: "#2db6fa" }}
                  size={80}
                />
                <h3>Website Development</h3>
                <p>
                  Designing and creating websites that are visually appealing
                  and easy to navigate.
                </p>
                <a href="/websites" class="read-more">
                  Read More
                  <ArrowRight />
                </a>
              </div>
            </div>

            <div
              class="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div class="service-box orange">
                <App
                  class="icon"
                  style={{ marginBottom: "1rem", color: "#f68c09" }}
                  size={80}
                />
                <h3>App Development</h3>
                <p>
                  Developing mobile applications for various platforms such as
                  iOS and Android.
                </p>
                <a href="/applications" class="read-more">
                  Read More
                  <ArrowRight />
                </a>
              </div>
            </div>

            <div
              class="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-delay="700"
            >
              <div class="service-box pink">
                <Robot
                  class="icon"
                  style={{ marginBottom: "1rem", color: "#f51f9c" }}
                  size={80}
                />
                <h3>AI Development</h3>
                <p>
                  Building and integrating AI application interfaces to offer
                  fast and efficient solutions.
                </p>
                <a href="/ai" class="read-more">
                  Read More
                  <ArrowRight />
                </a>
              </div>
            </div>

            <div
              class="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              <div class="service-box green">
                <Bag
                  class="icon"
                  style={{ marginBottom: "1rem", color: "#08da4e" }}
                  size={80}
                />
                <h3>E-Commerce Development</h3>
                <p>
                  Developing e-commerce websites that allow customers to
                  purchase products online.
                </p>
                <a href="/ecommerce" class="read-more">
                  Read More
                  <ArrowRight />
                </a>
              </div>
            </div>

            <div
              class="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-delay="500"
            >
              <div class="service-box red">
                <Folder2
                  class="icon"
                  style={{ marginBottom: "1rem", color: "#e9222c" }}
                  size={80}
                />
                <h3>Content Management</h3>
                <p>
                  Building platforms to manage and organize digital content.
                </p>
                <a href="/management" class="read-more">
                  Read More
                  <ArrowRight />
                </a>
              </div>
            </div>

            <div
              class="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-delay="600"
            >
              <div class="service-box purple">
                <PencilSquare
                  class="icon"
                  style={{ marginBottom: "1rem", color: "#b50edf" }}
                  size={80}
                />
                <h3>UI/UX Design</h3>
                <p>
                  Creating user-friendly interfaces and experiences for websites
                  and applications.
                </p>
                <a href="/ui" class="read-more">
                  Read More
                  <ArrowRight />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Services;
