import React from "react";
import InvoiceContactComponenet from "../component/InvoiceContactComonent";
import WebsiteComponent from "../component/WebsiteComponent";
import Footer from "../component/Footer";
const Websites = () => {
  return (
    <div>
      <WebsiteComponent />
      <InvoiceContactComponenet />
      <Footer />
    </div>
  );
};

export default Websites;
