import React from "react";
import InvoiceContactComponenet from "../component/InvoiceContactComonent";
import ManagementComponent from "../component/ManagementComponent";
import Footer from "../component/Footer";
const Management = () => {
  return (
    <div>
      <ManagementComponent />
      <InvoiceContactComponenet />
      <Footer />
    </div>
  );
};

export default Management;
