import React from "react";
import Hero from "../component/Hero";
import Services from "../component/services";
import Pricing from "../component/Pricing";
import Portfolio from "../component/Portfolio";
import Footer from "../component/Footer";
const Home = () => {
  return (
    <div>
      <Hero />
      <Services />
      <Pricing />
      <Portfolio />
      <Footer />
    </div>
  );
};

export default Home;
