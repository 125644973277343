import React from "react";
import InvoiceContactComponenet from "../component/InvoiceContactComonent";
import EcommerceComponent from "../component/EcommerceComponent";
import Footer from "../component/Footer";
const Ecommerce = () => {
  return (
    <div>
      <EcommerceComponent />
      <InvoiceContactComponenet />
      <Footer />
    </div>
  );
};

export default Ecommerce;
