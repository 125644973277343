import Contact from "./page/Contact";
import Home from "./page/Home";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./component/Header";

import Ai from "./page/Ai.jsx";
import Applications from "./page/Applications.jsx";
import Ecommerce from "./page/E-commerce.jsx";
import Management from "./page/Management.jsx";
import Ui from "./page/Ui.jsx";
import Websites from "./page/Websites.jsx";

function App() {
  return (
    <BrowserRouter>
      <div>
        <Header />
      </div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/ai" element={<Ai />} />
        <Route path="/applications" element={<Applications />} />
        <Route path="/ecommerce" element={<Ecommerce />} />
        <Route path="/management" element={<Management />} />
        <Route path="/websites" element={<Websites />} />
        <Route path="/ui" element={<Ui />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
