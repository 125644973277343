import React from "react";
import { ArrowRight } from "react-bootstrap-icons";
// import heroImg from "../assets/JPEG/hero-img.png";
import Lottie from "lottie-react";
import headerLottie from "../assets/lottie/front-end-development.json";

const Home = () => {
  return (
    <div>
      <section id="hero" class="hero d-flex align-items-center">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 d-flex flex-column justify-content-center">
              <h1 style={{ color: "white", dataaos: "fade-up" }}>
                Simplifying your digital experience
              </h1>
              <h2
                style={{ color: "#cacaca" }}
                data-aos="fade-up"
                data-aos-delay="400"
              >
                Designing, building, and maintaining the digital platforms you
                need
              </h2>
              <div data-aos="fade-up" data-aos-delay="600">
                <div class="text-center text-lg-start">
                  <a
                    style={{ borderRadius: "2rem" }}
                    href="#pricing"
                    class="btn-get-started btn-dark scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                  >
                    <span>Get Started</span>
                    <ArrowRight style={{ marginLeft: "0.2em" }} size={20} />
                  </a>
                </div>
              </div>
            </div>
            <div
              class="col-lg-6 hero-img"
              data-aos="zoom-out"
              data-aos-delay="200"
            >
              <Lottie class="img-fluid" animationData={headerLottie} />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
