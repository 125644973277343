import React from "react";
const ApplicationComponent = () => {
  return (
    <div>
      <section id="blog" class="blog">
        <div class="container" data-aos="fade-up">
          <div class="row">
            <div class="col-lg-12 entries">
              <article
                class="entry entry-single"
                style={{
                  backgroundColor: "white",
                  borderRadius: "1rem",
                  paddingLeft: " 5%",
                  paddingRight: " 5%",
                }}
              >
                <h2 class="entry-title">
                  <a href="/applications" style={{ color: "#f68c09" }}>
                    Application Development
                  </a>
                </h2>
                <div
                  style={{ marginTop: 20, marginLeft: "5%" }}
                  class="entry-content"
                >
                  <h3>📱 Revolutionize Your Business with Cutting-Edge Apps</h3>
                  <p>
                    Are you ready to propel your business to new heights? At
                    Devicefield, we specialize in turning your app vision into a
                    powerful reality. Whether you're a startup aiming to disrupt
                    the market or an established business seeking digital
                    transformation, our expert team is here to create bespoke,
                    high-performance apps tailored to your needs.
                  </p>
                </div>
                <div
                  style={{
                    marginTop: 20,
                    marginLeft: "5%",
                  }}
                  class="entry-content"
                >
                  <p style={{ fontSize: 20, fontWeight: "bold" }}>
                    Why Choose Us?
                  </p>
                  <ul style={{ listStyleType: "none" }}>
                    <li>
                      ✅ Expertise: Our seasoned developers are adept at
                      harnessing the latest technologies to craft innovative,
                      user-friendly apps.
                    </li>
                    <li>
                      ✅ Custom Solutions: No cookie-cutter templates here—each
                      app is meticulously designed to reflect your brand and
                      serve your unique goals.
                    </li>
                    <li>
                      ✅ Agile Development: We believe in agility and
                      adaptability, ensuring your app evolves alongside your
                      business needs.
                    </li>
                    <li>
                      ✅ Transparent Process: You'll be involved at every stage,
                      from concept to launch, ensuring your vision is fully
                      realized.
                    </li>
                  </ul>
                </div>
                <div
                  style={{ marginTop: 20, marginLeft: "5%" }}
                  class="entry-content"
                >
                  <p style={{ fontSize: 20, fontWeight: "bold" }}>
                    The Benefits
                  </p>
                  <ul style={{ listStyleType: "none" }}>
                    <li>
                      🌟 Enhanced User Experience: Delight your users with
                      seamless, intuitive app experiences that keep them coming
                      back for more.
                    </li>
                    <li>
                      🌟 Increased Engagement: Watch as your app becomes a
                      magnet for user engagement and interaction, driving
                      business growth.
                    </li>
                    <li>
                      🌟 Scalability: Built to grow with your business, our apps
                      can adapt and scale as your user base expands.
                    </li>
                  </ul>
                </div>
                <div
                  style={{ marginTop: 20, marginLeft: "5%" }}
                  class="entry-content"
                >
                  <h3>Limited Clients Accepted: Act Now!</h3>
                  <p>
                    Ready to witness the power of a bespoke app for your
                    business? Contact us today to schedule a consultation and
                    receive a complimentary project assessment. Your success
                    story starts here!
                    <br />
                    <br />
                    Don't let your competition steal the spotlight. Embrace
                    innovation and stay ahead with Devicefield today!
                  </p>
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ApplicationComponent;
