// UiComponent
import React from "react";
const UiComponent = () => {
  return (
    <div>
      <section id="blog" class="blog">
        <div class="container" data-aos="fade-up">
          <div class="row">
            <div class="col-lg-12 entries">
              <article
                class="entry entry-single"
                style={{
                  backgroundColor: "white",
                  borderRadius: "1rem",
                  paddingLeft: " 5%",
                  paddingRight: " 5%",
                }}
              >
                <h2 class="entry-title">
                  <a href="/ui" style={{ color: "#f68c09" }}>
                    UX/UI Development
                  </a>
                </h2>
                <div
                  style={{ marginTop: 20, marginLeft: "5%" }}
                  class="entry-content"
                >
                  <h3>Crafting Seamless Experiences for Tomorrow 🛠️ </h3>
                  <p>
                    At Devicefield, we specialize in turning digital dreams into
                    intuitive, immersive realities. Our UX/UI development
                    service isn’t just about lines of code; it's about sculpting
                    experiences that users love. We fuse creativity with
                    functionality, ensuring every click, swipe, and interaction
                    feels like second nature.
                  </p>
                </div>
                <div
                  style={{
                    marginTop: 20,
                    marginLeft: "5%",
                  }}
                  class="entry-content"
                >
                  <p style={{ fontSize: 20, fontWeight: "bold" }}>
                    Why Choose Us?
                  </p>
                  <ul style={{ listStyleType: "none" }}>
                    <li>
                      🌟 Innovative Solutions: We don’t just follow trends; we
                      set them. Our team pioneers innovative design strategies
                      to keep you ahead in the digital race.
                    </li>
                    <li>
                      🔍 User-Centric Approach: Your users are at the heart of
                      everything we do. We meticulously craft designs that
                      resonate with your audience, ensuring seamless navigation
                      and a captivating interface.
                    </li>
                    <li>
                      🚀 Performance-Driven Design: It's not just about
                      aesthetics; it's about performance. Our designs are
                      optimized for speed, functionality, and conversion.
                    </li>
                    <li>
                      🤝 Collaborative Process: We believe in working
                      hand-in-hand with you. Your vision, our expertise –
                      together, we create magic.
                    </li>
                  </ul>
                </div>
                <div
                  style={{ marginTop: 20, marginLeft: "5%" }}
                  class="entry-content"
                >
                  <p style={{ fontSize: 20, fontWeight: "bold" }}>
                    Our Offerings
                  </p>
                  <ul style={{ listStyleType: "none" }}>
                    <li>
                      🔧 UI/UX Development: From wireframes to polished
                      interfaces, we breathe life into your concepts.
                    </li>
                    <li>
                      🎨 Visual Design: Eye-catching, sleek, and on-brand
                      visuals that elevate your digital presence.
                    </li>
                    <li>
                      🧩 User Testing: We don’t just stop at the design phase;
                      we rigorously test and refine to ensure a flawless user
                      experience.
                    </li>
                  </ul>
                </div>
                <div
                  style={{ marginTop: 20, marginLeft: "5%" }}
                  class="entry-content"
                >
                  <h3>Limited Clients Accepted: Act Now!</h3>
                  <p>
                    Ready to witness the power of a bespoke app for your
                    business? Contact us today to schedule a consultation and
                    receive a complimentary project assessment. Your success
                    story starts here!
                    <br />
                    <br />
                    Don't let your competition steal the spotlight. Embrace
                    innovation and stay ahead with Devicefield today!
                  </p>
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default UiComponent;
